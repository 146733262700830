// we need a relative file path to Common or else build:browsePPKs and build:registerForm break
import session from '../../Common/scripts/session';
import getFetchRequestData from './get-fetch-request-data';
import isRefreshTokenValid from './is-refresh-token-valid';
import queryKind from '../useQueryApi';
const jwt = require('jsonwebtoken');

// * first we'll check for a valid refresh token, then we'll actually get the data

const fetchRefresh = async handleFetchFailure => {
    const tokens = await session.getUserData();

    // check the refresh token
    let refreshTokenIsValid = isRefreshTokenValid(tokens);

    // if it's invalid then we make an api call to get a new one
    if (!refreshTokenIsValid) {
        const { path, init } = getFetchRequestData(queryKind.refresh, tokens);
        const response = await fetch(`${path}`, init);
        const responseData = await response.json();
        if (!response.ok) {
            handleFetchFailure(response, responseData);
        } else {
            // save the new token to the cookie
            session.storeUserData(
                {
                    accessToken: responseData.accessToken,
                    refreshToken: responseData.refreshToken,
                    idToken: jwt.decode(responseData.idToken)
                },
                true
            );
        }
    }
};

const fetchDataKind = async (kind, requestBody, handleFetchFailure) => {
    const tokens = await session.getUserData();
    const { path, init } = getFetchRequestData(kind, tokens, requestBody);
    const response = await fetch(`${path}`, init);
    const responseData = await response.json();

    if (!response.ok) {
        handleFetchFailure(response, responseData);
        return false;
    } else {
        return responseData;
    }
};

const fetchData = (queryKind, requestBody, handleFetchFailure) => {
    // if the user was supposed to be logged in, check the refresh token
    if (queryKind.createCompany || queryKind.joinCompany) {
        // check and/or fetch the refresh token, then get the requested data
        return fetchRefresh().then(() => fetchDataKind(queryKind, requestBody, handleFetchFailure));
    }
    return fetchDataKind(queryKind, requestBody, handleFetchFailure);
};

export default fetchData;
