import React from 'react';
import smartFocusRing from 'smart-focus-ring';
import Routes from 'Routes';
import 'services/fontAwesome';
import 'styles/global-styles.scss';

smartFocusRing();

export default function App() {
    return <Routes />;
}
