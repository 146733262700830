const isRefreshTokenValid = tokens => {
    const timeNow = new Date().getTime();
    const expirationTime = tokens?.idToken?.exp * 1000;

    if (timeNow >= expirationTime) {
        return false;
    } else {
        return true;
    }
};

export default isRefreshTokenValid;
