import session from 'Common/scripts/session';

export default (data, formErrorActions) => {
    const { RedirectUrl, Description, Message, ErrorCode } = data;

    if (ErrorCode >= 10001 && ErrorCode < 20000) {
        session.storeUserData({ pageErrorMsg: Description }).then(() => {
            console.error(`${Message}: ${Description}`);
            return '/error/';
        });
    }

    if (ErrorCode >= 20001 && ErrorCode < 30000) {
        if (formErrorActions) formErrorActions(Description);
        console.error(`${Message}: ${Description}`);
        return;
    }

    if (ErrorCode === 40000) {
        return RedirectUrl;
    }
};
