import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import checkIfRestricted from './scripts/check-if-restricted';

// todo: can we also check for an expired token here?

export default function RestrictedRoute(props) {
    const { component: Component, location, path, ...rest } = props;
    const [state, setState] = useState(null);

    useEffect(() => {
        checkIfRestricted(path, location.state?.referrer).then(data => {
            setState(data);
        });
    }, []);

    if (!state) {
        return null;
    }

    return (
        // prettier-ignore
        <Route path={path} location={location} {...rest} render={props =>
                state.path ? (
                    <Redirect
                        to={{
                            pathname: state.path,
                            state: { redirectMessage: state.message }
                        }}
                    />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
}
