import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import * as serviceWorker from 'serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// This service worker is disabled currently. While service workers can help
// the app run quicker - and work offline - it causes the index.html to be
// cached, unless the app is reloaded.
//
// This can be changed from `unregister` to `register` to re-enable service workers.
serviceWorker.unregister();
