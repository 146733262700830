import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Loader from 'Common/components/Loader';
import RestrictedRoute from 'routes/RestrictedRoute';

const Register = lazy(() => import('pages/Register'));
const SignIn = lazy(() => import('pages/SignIn'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const NewPassword = lazy(() => import('pages/NewPassword'));
const Verification = lazy(() => import('pages/Verification'));
const NotFound = lazy(() => import('pages/NotFound'));
const Error = lazy(() => import('pages/Error'));
const AccountCreated = lazy(() => import('pages/AccountCreated'));
const CreateCompany = lazy(() => import('pages/CreateCompany'));
const JoinCompany = lazy(() => import('pages/JoinCompany'));
const ThankYou = lazy(() => import('pages/ThankYou'));

function Routes() {
    return (
        <Router>
            <Suspense fallback={<Loader loading={true} isPageLoader />}>
                <Switch>
                    {/*
                    // * https://github.com/ReactTraining/react-router/issues/820#issuecomment-313794272
                    // * for any route that doesn't have a trailing slash, add it
                    */}
                    <Route
                        path="/:url*"
                        exact
                        strict
                        render={props => <Redirect to={`${props.location.pathname}/`} />}
                    />
                    <Route path="/" component={Register} exact />
                    <Route path="/signin/" component={SignIn} exact strict />
                    <Route path="/forgot-password/" component={ForgotPassword} exact strict />
                    <Route path="/new-password/" component={NewPassword} exact strict />
                    <Route path="/verification/:userToken/" component={Verification} exact strict />
                    <RestrictedRoute
                        path="/accountcreated/"
                        component={AccountCreated}
                        exact
                        strict
                    />
                    <RestrictedRoute
                        path="/create-company/"
                        component={CreateCompany}
                        exact
                        strict
                    />
                    <RestrictedRoute path="/join-company/" component={JoinCompany} exact strict />
                    <Route path="/thank-you/" component={ThankYou} />
                    <Route path="/error/" component={Error} exact strict />
                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </Router>
    );
}

export default Routes;
