const Cookies = require('js-cookie');
const domain = window.location.hostname.split('.').slice(-2).join('.');
const D = { path: '/', domain };
// const { CrossStorageClient } = require('cross-storage');

// TODO: Build correct domain here.
// const storage = new CrossStorageClient(
//   'https://test-register.smartdeploy.com/cross-storage-hub.html'
// );

const getUserData = async () => {
  return Cookies.getJSON();
};

const storeUserData = async (newUserObj, overwrite = true) => {
  let data = await getUserData();
  for (let k in newUserObj) {
    if (overwrite || !data[k]) {
      Cookies.set(k, newUserObj[k], D);
    }
  }
};

const deleteUserData = async (cookieName) => {
  // if a name was given, delete a specific cookie
  if (cookieName) {
    Cookies.remove(cookieName, D);
    return;
  }

  // otherwise delete everything
  let data = await getUserData();
  for (let k in data) {
    Cookies.remove(k, D);
  }
};

const session = { getUserData, storeUserData, deleteUserData };

export default session;
