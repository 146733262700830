import session from 'Common/scripts/session';
import handleErrorCodes from 'services/handleErrorCodes';

// * This function returns an arry with response data and a redirect value
// * If response data is null, a redirect might be present (depending on the api call)
// * Otherwise the redirect will be null

// * Why? Bc Routes don't have access to the history prop
// * so we need to manage redirects differently from handleApiRequest.js

export default async (values, fetchFn) => {
    try {
        const { response, data } = await fetchFn(values);

        // todo: see 'Error Page' in Todo.md
        if (response.status >= 400 && response.status < 500) {
            const redirect = handleErrorCodes(data);
            return [null, redirect];
        }

        return [data, null];
    } catch (err) {
        const pageErrorMsg = `An error occured: ${err.message} Please contact <a href="mailto:support@smartdeploy.com?subject=Invalid User Token">support@smartdeploy.com</a>.`;
        return session.storeUserData({ pageErrorMsg }).then(() => {
            console.error(err);
            return [null, '/error/'];
        });
    }
};
