import { queryKind } from '../useQueryApi';
// we need a relative file path to Common or else build:browsePPKs and build:registerForm break
import domain from '../../Common/scripts/domain';

// this returns an object containing path, init and a body when needed
function getFetchRequestData(kind, tokens, values) {
    const dynamicDomain = domain.getDynamicRegistrationApi(process.env.NODE_ENV);
    // const dynamicRegistrationDomain = domain.getDynamicRegistrationApi(process.env.NODE_ENV);
    let opts = {};

    switch (kind) {
        case queryKind.verifyUser:
            opts = {
                path: `${dynamicDomain}/users/VerifyNewSmartDeployUser`,
                init: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values) // an object that contains verification code, role, phone, password
                }
            };
            break;
        case queryKind.verifyCode:
            opts = {
                path: `${dynamicDomain}/users/${values.userToken}/verification/${values.verificationCode}`,
                init: {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            };
            break;
        case queryKind.tokenIsValid:
            opts = {
                path: `${dynamicDomain}/users/${values.userToken}/isValid`,
                init: {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            };
            break;
        case queryKind.createUser:
            opts = {
                path: `${dynamicDomain}/users/CreateNewSmartDeployUser`,
                init: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values) // an object that contains firstName, lastName, email
                }
            };
            break;
        case queryKind.createCompany:
            opts = {
                path: `${dynamicDomain}/accounts/CreateNewCompany`,
                init: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    },
                    body: JSON.stringify({
                        urlReferrer: window.location.href,
                        ...values // an object that contains company name and various project details
                    })
                }
            };
            break;
        case queryKind.joinCompany:
            opts = {
                path: `${dynamicDomain}/accounts/JoinExistingCompany`,
                init: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    },
                    body: JSON.stringify(values) // an object that contains primary contact email
                }
            };
            break;
        case queryKind.d365LoginUser:
            opts = {
                path: `${dynamicDomain}/users/D365ChoiceHandshakeDoubleCheck`,
                init: {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    },
                    body: JSON.stringify({ urlReferrer: window.location.href })
                }
            };
            break;
        case queryKind.refresh:
            opts = {
                path: `${dynamicDomain}/users/refresh`,
                init: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    },
                    body: JSON.stringify({ RefreshToken: tokens.refreshToken })
                }
            };
            break;
        case queryKind.newPassword:
            opts = {
                path: `${dynamicDomain}/users/new-password`,
                init: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values) // an object that contains Email
                }
            };
            break;
        case queryKind.changePassword:
            opts = {
                path: `${dynamicDomain}/users/password`,
                init: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values) // an object that contains Password and Token
                }
            };
            break;
        case queryKind.ppkList:
            opts = {
                // path: `${dynamicDomain}/platformpack/manufacturers/${values.id}`,
                path: `${dynamicDomain}/platformpacks/list`,
                init: {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            };
            break;
    }

    if (window.env.API_SUB_KEY && opts.init?.headers) {
        opts.init.headers['Ocp-Apim-Subscription-Key'] = window.env.API_SUB_KEY;
    }

    return opts;
    // prettier-ignore
}

export default getFetchRequestData;
