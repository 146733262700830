import session from 'Common/scripts/session';
import handleRouteApiRequest from 'services/handleRouteApiRequest';
import MessageAndPath from 'services/message-and-path';
// todo: maybe move these two files outside the hooks folder?
import getFetchRequestData from 'hooks/scripts/get-fetch-request-data';
import { queryKind } from 'hooks/useQueryApi';
const jwt = require('jsonwebtoken');

// this file is similar to \pages\SignIn\scripts\get-signin-redirect.js fwiw

const routeToCorrectPage = (currentPath, referrer, registrationStatus) => {
    // join or create company pages shouldn't be directly accessible
    // users should only arrive on them after selecting a link on accountcreated
    if (
        (currentPath === '/join-company/' || currentPath === '/create-company/') &&
        referrer !== '/accountcreated/'
    ) {
        return new MessageAndPath('Please make a selection.', '/accountcreated/');
    }

    switch (registrationStatus) {
        case 0:
            return new MessageAndPath('Please create an account.', '/');
        case 5:
        case 6:
        case 3:
        case 4:
        case 7:
        case 8:
        case 9:
            // go to the requested page page
            return {};
        default:
            return new MessageAndPath('Please sign in.', '/signin/');
    }
};

// some of this is already built out and integrated with useQueryApi
// but we're redifining it here so it works outside the context of a react component
const fetchRefresh = async () => {
    const { path, init } = getFetchRequestData(queryKind.refresh);
    const response = await fetch(`${path}`, init);
    return response;
};

const checkIfRestricted = async (currentPath, referrer = null) => {
    const sess = await session.getUserData();

    // if we have data in the session
    if (Object.values(sess).length > 0) {
        const idToken = sess.idToken;
        const registrationStatus = idToken.extension_userRegistrationStatus;
        const expirationTime = idToken.exp * 1000;
        const timeNow = new Date().getTime();

        // check if the token is expired
        if (timeNow >= expirationTime) {
            // return the results so the info below makes it back up to the Route component

            const [responseData, redirect] = await handleRouteApiRequest({}, fetchRefresh);

            if (responseData) {
                const state = {
                    idToken: jwt.decode(responseData.IdToken),
                    refreshToken: responseData.RefreshToken,
                    accessToken: responseData.AccessToken
                };
                // save the new responseData tokens and overwrite the old tokens
                session.storeUserData(state, true).then(() => {});
                return routeToCorrectPage(currentPath, referrer, registrationStatus);
            }

            if (redirect) {
                return new MessageAndPath(null, redirect);
            }
        } else {
            return routeToCorrectPage(currentPath, referrer, registrationStatus);
        }
    } else {
        // if there is no session do this
        return new MessageAndPath('Please sign in.', '/signin/');
    }
};
export default checkIfRestricted;
