import React, { useState, useEffect } from 'react';
import ConnectionError from '../Common/components/ConnectionError';
import fetchData from './scripts/fetch-data';

// * The component calling this hook is responsible for
// 1. Displaying errors as needed
// 2. Handling redirect urls
// 3. Ensuring that the data it recieved is what was needed
//   i.e. if the api responed with 200 and returned an empty object
//   the component may still need to display a notification for the user

// * Reference: https://www.robinwieruch.de/react-hooks-fetch-data

const queryKind = {
    verifyUser: 'verifyUser',
    verifyCode: 'verifyCode',
    tokenIsValid: 'tokenIsValid',
    createUser: 'createUser',
    createCompany: 'createCompany',
    joinCompany: 'joinCompany',
    d365LoginUser: 'd365LoginUser',
    refresh: 'refresh',
    newPassword: 'newPassword',
    changePassword: 'changePassword',
    ppkList: 'ppkList'
};

function useQueryApi(kind, requestBody = null, isReady = true) {
    // set to null instead of false because sometimes components need to know if the values have been set yet or not
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);

    useEffect(() => {
        let isSubscribed = true;

        // if we already have data, don't fetch
        if (data?.length > 0) {
            if (isSubscribed) setLoading(false);
            return undefined;
        }

        // if the component calling useQueryApi isn't ready yet, don't fetch
        if (!isReady) return undefined;

        const fetching = async () => {
            try {
                if (isSubscribed) setLoading(true);

                // this runs if the fetch response was not ok
                const handleFetchFailure = (response, responseData) => {
                    // set an error for the parent component to use
                    if (isSubscribed) {
                        setError(`${responseData.Description}`);
                        setErrorCode(`${responseData.ErrorCode}`);
                    }
                    // check if a redirect is needed
                    if (responseData?.ErrorCode === 40000) {
                        if (isSubscribed) setRedirectUrl(responseData.RedirectUrl);
                    } else {
                        throw Error(
                            `Connection to the SmartDeploy API failed ${response.status} (${response.statusText})`
                        );
                    }
                };

                // console.log(`running query for ${kind}`);

                // fetch the requested data
                const responseData = await fetchData(kind, requestBody, handleFetchFailure);

                // set data
                if (responseData && isSubscribed) {
                    setData(responseData);
                }
            } catch (err) {
                // if the !response.ok check did NOT setError & throw Error
                // then display the default error message
                if (isSubscribed && !err.message.startsWith('Connection')) {
                    setError(<ConnectionError />);
                }
                // This is resetting the valid error coming back from the Api. Talk with Jonathan about it when he is back.
                //else {
                //    setError(
                //        'An unexpected exception has occured, please contact Support or call us at 888-7DEPLOY.'
                //    );
                //}
            } finally {
                if (isSubscribed) setLoading(false);
            }
        };

        fetching();

        // cleanup when the component is unmountend
        return () => {
            isSubscribed = false;
            setData(null);
            setLoading(null);
            setError(null);
            setErrorCode(null);
        };
    }, [isReady]);

    // we can return the errorCode here too if we need to
    return { data, loading, error, redirectUrl };
}

export { useQueryApi as default, queryKind };
